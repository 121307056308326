import React from 'react';
import './style.css';
import {Titulo } from './titulo';
import img1 from './img/1.jpg';
import img2 from './img/2.png';
import img3 from './img/3.png';
import img4 from './img/4.png';
import img5 from './img/5.jpg';
import img6 from './img/6.png';
import img7 from './img/7.jpg';
import img8 from './img/8.png';
import Himg from '../header/logo-header.png';
import JHeader from "../header";

function Servicios(){
    return(
        <div>
            <JHeader img={Himg} />
            <Titulo titulo="nuestros"/>
        
        <div className="container servicios">
            
            <div className="row">
                <div className="col-12 col-md-5" id="calderas">
                <h2>LAVADO Y LIMPIEZA</h2>
                <h3>QUÍMICA DE CALDERAS</h3>
                <p>
                Lavado alcalino (boil-out) para remoción de grasas y aceites, en equipos nuevos y/o contaminados.
                </p>
                <p>
                Limpieza química ácida para remoción de incrustaciones y depósitos en lado agua.
                </p>
                <p>
                Limpieza  y pasivación de tuberías de calentadores de aire, economizador y superheater por acumulación de cenizas de combustión y depósitos en el lado fuego de la caldera.
                </p>
                </div>
                <div className="col-12 col-md-7">
                     <img src={img1} className="img-fluid mx-auto d-block" alt=""/>
                </div>
            </div>
            <div className="row">
            <div className="col-12">
                <img src={img2} className="img-fluid mx-auto d-block" alt=""/>
            </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-7 order-2 order-md-1">
                     <img src={img3} className="img-fluid mx-auto d-block" alt=""/>
                </div>
                <div className="col-12 col-md-5 order-1 order-md-2" id="enfriamiento">
                

                <h2>SISTEMAS</h2>
                <h3>DE ENFRIAMIENTO</h3>
                <p>
                Limpieza química y mecánica para retiro de depósitos inorgánicos e incrustaciones en líneas y equipos usuarios del sistema.
                </p>
                <p>
                Limpieza química y mecánica para retiro de material orgánico (biofouling). 
                </p>
                <p>
                Limpieza y mantenimiento general (rejillas, atrapanieblas, relleno,  bandejas y boquillas de distibución).
                </p>
                </div>
                
            </div>
            <div className="row">
            <div className="col-12">
                <img src={img4} className="img-fluid mx-auto d-block" alt=""/>
            </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-7 order-2 order-md-1">
                     <img src={img5} className="img-fluid mx-auto d-block" alt=""/>
                </div>
                <div className="col-12 col-md-5 order-1 order-md-2" id="calor">
                
                 

                <h2>INTERCAMBIADORES</h2>
                <h3>DE CALOR</h3>
                <p>
                Lavado  químico y limpieza mecánica de intercambiares  de calor.
                </p>
                <p>
                Lavado químico y pasivación de intercambiadores  de calor de metalurgias especiales (aceros austeníticos)
                </p>
                <p>
                Limpieza química de intercambiadores  de calor cuando no es posible retirar el haz de tubos.
                </p>
                </div>
                
            </div>
            <div className="row">
            <div className="col-12">
                <img src={img6} className="img-fluid mx-auto d-block" alt=""/>
            </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-5" id="tuberias">
                

                <h2>TORRES, TAMBORES</h2>
                <h3>Y TUBERÍAS</h3>
                <p>
                Descontaminación de Drunes (Tambores) y tanques para trabajos de mantenimiento y/o inspección interna.

                </p>
                <p>
                Limpieza química de tanques de almacenamiento (de hidrocarburos, combustibles y productos químicos).

                </p>
                <p>
                Limpieza química y descontaminación de torres, tambores y líneas de proceso (tuberías). 
                </p>
                </div>
                <div className="col-12 col-md-7">
                     <img src={img7} className="img-fluid mx-auto d-block" alt=""/>
                </div>
                <div className="col-12">
                    <img src={img8} className="img-fluid mx-auto d-block" alt=""/>
                </div>
            </div>

        </div>
        </div>
    )
}

export default Servicios;