import React from 'react';
import './style.css';
import introImg from './intro.png';
import { Link } from "react-router-dom";


function Intro(){
    return(
        <div>
        
        <div className="container intro">
            <div className="row">
                <div className="col-12 col-md-6">
                <h2>GRANDES <br/>
                <span>IDEAS</span> <br/>
                EN MANTENIMIENTO</h2>
                <p>En Mequinsa somos una empresa dedicada al servicio de mantenimiento, limpieza química, limpieza mecánica y descontaminación de equipos industriales como: calderas, sistemas de enfriamiento, intercambiadores de calor, tanques y tuberías de proceso, entre otros.</p>
                <Link className="btn btn-primary" to="/acercade">CONÓCENOS</Link>
                </div>
                <div className="col-12 col-md-6">
                    <img src={introImg} className="img-fluid mx-auto d-block" alt=""/>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Intro;