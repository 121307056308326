
import React, { Component } from "react";

import './style.css'
import Slider from "react-slick";
import imagelink from './aspas.jpg';
import imagelink2 from './enfriamiento.jpg';
import imagelink3 from './tubos.jpg';
import imagelink4 from './Servicio_torres.jpg'
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const datos =[
  {
      id:1,
      img:imagelink,
      cartSubTitle:'INDUSTRIAL',
      cartTitle:'CALDERAS',
      parrafo:'Lavado alcalino (boil-out) para remoción de grasas y aceites, en equipos nuevos y/o contaminados.',
      url:'/servicios#calderas'
  },
  {
      id:2,
      img:imagelink2,
      cartSubTitle:'SISTEMAS DE ',
      cartTitle:'ENFRIAMIENTO',
      parrafo:'Limpieza química y mecánica para retiro de depósitos inorgánicos e incrustaciones en líneas y equipos usuarios del sistema.',
      url:'/servicios#enfriamiento'
  },
  {
      id:3,
      img:imagelink3,
      cartSubTitle:'INTERCAMBIADORES',
      cartTitle:'DE CALOR',
      parrafo:'Lavado  químico y limpieza mecánica de intercambiares  de calor.',
      url:'/servicios#calor'
  },
  {
    id:4,
    img:imagelink4,
    cartSubTitle:'TORRES, TAMBORES',
    cartTitle:'Y TUBERÍAS',
    parrafo:'Descontaminación de Drunes (Tambores), limpieza química de tanques y descontaminación de torres',
    url:'/servicios#tuberias'
}
]

const listItems = datos.map((number) =>
    <div key={number.id}>  
        <div className="card">
            <img src={number.img} className="card-img-top" alt="..." />
            <div className="card-body">
            <h6>{number.cartSubTitle}</h6>
            <h5 className="card-title">{number.cartTitle}</h5>
            <p className="card-text">{number.parrafo}</p>
            <Link className="btn btn-primary btn-slider" to={number.url}>Ver más</Link>

            </div>
        </div>
    </div>
);

export default class Responsive extends Component {
    render() {
        var settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          pauseOnHover: false,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        return (
          <div>
            <Slider {...settings}>
            
            {listItems}
            
            
            </Slider>
            
          </div>
        );
      }
}

