import React from 'react';
import IntroAcerca from './intro';
import Nexperiencia from './nexperiencia';
import Nclientes from './nuestrosClientes';
import {Titulo} from './titulo';
import './style.css';
import Himg from '../header/logo-header.png';
import JHeader from "../header";


function AcercaDe() {
  return (
    <div>
        <JHeader img={Himg} />
        <Titulo titulo="Acerca de"/>
        <IntroAcerca/>
        <Nexperiencia/>
        <Nclientes/>
    </div>
    
  );
}

export default AcercaDe;
