import React from 'react';
import Logo from './logo-footer.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import logoGaycom from './wil-micro.png';
import ScrollUpButton from "react-scroll-up-button";

function JFooter(){
    return(
        <div>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                    <img src={Logo} className="img-fluid d-block mx-auto" alt=""/>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                    <h3>SERVICIOS</h3>
                        <ul className="lineas">
                            <li><Link to='/servicios#calderas'>Lavado y limpieza Química  de Calderas</Link></li>
                            <li><Link to='/servicios#enfriamiento'>Limpieza de Sistemas de Enfriamiento</Link></li>
                            <li><Link to='/servicios#calor'>Limpieza de Intercambiadores de Calor</Link></li>
                            <li><Link to='/servicios#tuberias'>Limpieza y Descontaminación de Drunes y Tanques</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <h3>MEQUINSA CALI</h3>
                        <ul className="fa-ul">
                            <li>
                                <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                    <FontAwesomeIcon icon="map-marker-alt" className="fa-inverse fa-stack-1x" />
                                </span>
                                </span>
                                Calle 56  No. 3A – 50 Cali, Colombia</li>
                            <li> 
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cinstagram" />
                                    <FontAwesomeIcon icon="phone" className="fa-inverse fa-stack-1x" />
                                </span>
                                </span>
                                <a rel="noopener noreferrer" target='_blank' href="tel:+5723799708">
                                 Teléfono: (+57) 2-379 9708
                                 </a>
                                 </li>
                            <li>
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                    <FontAwesomeIcon icon={['fab', 'whatsapp']} className="fa-inverse fa-stack-1x" />
                                </span>
                            </span>
                            <a rel="noopener noreferrer" target='_blank' href="tel:+573214521251">
                                Cel (+57) 321 4521251</a></li>
                            <li>
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cfacebook" />
                                    <FontAwesomeIcon icon="envelope" className="fa-inverse fa-stack-1x" />
                                </span>
                            </span>
                            <a rel="noopener noreferrer" target='_blank' href="mailto:jcastillo@mequinsa.com"> jcastillo@mequinsa.com </a></li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <h3>BARRANCABERMEJA</h3>
                        <ul className="fa-ul">
                            <li>
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                    <FontAwesomeIcon icon="map-marker-alt" className="fa-inverse fa-stack-1x" />
                                </span>
                                </span>
                                Calle 34  No. 34 – 99 B/bermeja, Colombia</li>
                            <li>
                            <span className="fa-li">
                            <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cinstagram" />
                                    <FontAwesomeIcon icon="phone" className="fa-inverse fa-stack-1x" />
                                </span>
                                </span>
                                <a rel="noopener noreferrer" target='_blank' href="tel:+5776125323">
                                Teléfono: (+57) 7-6125323
                                </a>
                            </li>
                            <li>
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                    <FontAwesomeIcon icon={['fab', 'whatsapp']} className="fa-inverse fa-stack-1x" />
                                </span>
                                </span>
                                <a rel="noopener noreferrer" target='_blank' href="tel:+573214521251">
                                Cel (+57) 321 4521251</a></li>
                            <li>
                            <span className="fa-li">
                                <span className="fa-stack fa-xs">
                                    <FontAwesomeIcon icon="circle" className="fa-stack-2x cfacebook" />
                                    <FontAwesomeIcon icon="envelope" className="fa-inverse fa-stack-1x" />
                                </span>
                            </span>
                               <a rel="noopener noreferrer" target='_blank' href="mailto:jcastillo@mequinsa.com"> jcastillo@mequinsa.com </a></li>
                        </ul>
                        <h3>SÍGUENOS</h3>
                            <a rel="noopener noreferrer" target='_blank' href="http://google.com">
                            <span className="fa-stack fa-lg">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cfacebook" />
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a rel="noopener noreferrer" target='_blank' href="http://google.com">
                            <span className="fa-stack fa-lg">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cinstagram" />
                                <FontAwesomeIcon icon={['fab', 'instagram']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a rel="noopener noreferrer" target='_blank' href="http://google.com">
                            <span className="fa-stack fa-lg">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cyoutube" />
                                <FontAwesomeIcon icon={['fab', 'youtube']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a rel="noopener noreferrer" target='_blank' href="https://wa.me/573214521251">
                            <span className="fa-stack fa-lg">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                    </div>
                </div>
            </div>
        </footer>
        <ScrollUpButton />
        <div className="postfooter text-center">
            COPYRIGTH <a rel="noopener noreferrer" target="_blank" style={{color:'#ff7700'}} href="http://arkcom.com.co"><img src={logoGaycom} style={{verticalAlign:'bottom'}} alt=""/> ARKCOM </a> 2019 TODOS LOS DERECHOS RESERVADOS
        </div>
        </div>
    );
}

export default JFooter;