import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'
import { Link } from "react-router-dom";
import LogoSmart from './logo-header.png';

function JHeader(props){
    return(
        <header className="header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-4 redes">
                            <a target="_blank" rel="noopener noreferrer" href="http://google.com">
                            <span className="fa-stack fa-sm">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cfacebook" />
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="http://google.com">
                            <span className="fa-stack fa-sm">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cinstagram" />
                                <FontAwesomeIcon icon={['fab', 'instagram']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="http://google.com">
                            <span className="fa-stack fa-sm">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cyoutube" />
                                <FontAwesomeIcon icon={['fab', 'youtube']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/573214521251">
                            <span className="fa-stack fa-sm">
                                <FontAwesomeIcon icon="circle" className="fa-stack-2x cwhatsapp" />
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} className="fa-inverse fa-stack-1x" />
                            </span>
                            </a>
                    </div>
                    <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link to='/'> 
                                <img src={LogoSmart} className="d-block d-md-none mx-auto img-fluid" style={{height:80}} alt=""/>
                                <img src={props.img} className="d-none d-md-block mx-auto img-fluid" style={{height:75}} alt=""/>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                    <span className="texto-menu">MENÚ</span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                    <div className="navbar-nav ml-auto">
                                    <Link className="nav-item nav-link" to='/'>Inicio</Link>
                                    <Link className="nav-item nav-link" to='/acercade'>Acerca de</Link>
                                    <Link className="nav-item nav-link" to='/servicios'>Servicios</Link>
                                    <Link className="nav-item nav-link" to='/contacto'>Contacto</Link>
                                    </div>
                                </div>
                            </nav>
                        
                        
                    </div>
                </div>
            </div>
            
        </header>
    );
}

export default JHeader;