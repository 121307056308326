import React from 'react';
import './style.css';
import datot from './data';


const listItems2 = datot.map((number2) =>

  <tr key={number2.id}>
    <td>{number2.f1}</td>
    <td>{number2.f2}</td>
    <td>{number2.f3}</td>
    <td>{number2.f4}</td>
  </tr>

);

function Nexperiencia() {
  return (
    <div className="nexperiencia">
      <h1>NUESTRA</h1>
      <div className="contenedor">
        <h2>EXPERIENCIA</h2>

        <div className="container">
          <div className="table-responsive">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th>CIUDAD Y EMPRESA</th>
                  <th>PLANTA</th>
                  <th>CARGO / DESCRIPCIÓN</th>
                  <th>FECHA TRABAJOS / CONTACTOS</th>
                </tr>
              </thead>
              <tbody>
                {listItems2}
              </tbody>
            </table>
          </div>

        </div>
      </div>

    </div>

  );
}

export default Nexperiencia;
