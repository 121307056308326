const datot = [
  {
    id: 't1',
    f1: 'Consorcio Turnaround - Alliance (ECOPETROL GRB) Barrancabermeja',
    f2: 'UNIDAD  DE HIDROTRATAMIENTO PLANTA HDT',
    f3: 'Coordinador y ejecutor de Lavado químico y pasivación equipos E-4701 Y E-4702  de la planta HDT de Ecopetrol GRB',
    f4: 'Febrero de 2019 Carlos Barón -  Edisson Ruiz Santamaría',
  },
  {
    id: 't2',
    f1: 'Consorcio OBTC  Colombia (ECOPETROL GRB) Barrancabermeja',
    f2: 'UNIDAD DE SERVICIOS INDUSTRIALES CALDERAS SB-2955 Y SB-2952',
    f3: 'Coordinador y ejecutor Lavado químico y pasivación de las calderas SB-2955 Y SB-2404 de servicios industriales de Ecopetrol GRB',
    f4: 'Enero de 2019 Javier Vernaza -  Juan Carlos Toro',
  },
  {
    id: 't3',
    f1: 'Consorcio OBTC  Colombia (ECOPETROL GRB) Barrancabermeja',
    f2: 'PLANTA POLIETILENO I DE ECOPETROL GRB',
    f3: 'Coordinador y ejecutor durante los trabajos de lavado químico de los intercambiadores de calor de la planta Polietileno I',
    f4: 'Marzo de 2019 Freddy Rueda -  Juan Carlos Toro',
  },
  {
    id: 't4',
    f1: 'Consorcio Turnaround - Alliance (ECOPETROL GRB) Barrancabermeja',
    f2: 'UNIDAD DE SERVICIOS INDUSTRIALES CALDERAS SB-2955',
    f3: 'Coordinador y ejecutor durante los trabajos de limpieza química (remoción de hidrocarburo) de la caldera B-2955',
    f4: 'Febrero de 2019 Roberto Navarro -  Juan Carlos Toro',
  },
  {
    id: 't5',
    f1: 'Consorcio OBTC  Colombia (ECOPETROL GRB) Barrancabermeja',
    f2: 'PLANTA POLIETILENO I DE ECOPETROL GRB',
    f3: 'Coordinador y ejecutor durante los trabajos de lavado químico de los intercambiadores de calor de la planta Polietileno I',
    f4: 'Febrero de 2019 Roberto Navarro -  Juan Carlos Toro',
  },
  {
    id: 't6',
    f1: 'AIR LIQUIDE FEMSA COCA-COLA Tocancipá',
    f2: 'TORRE DE ENFRIAMIENTO PASTEUR',
    f3: 'Supervisor, técnico operativo y coordinador (Empresa Mequinsa) durante los trabajos de lavado químico y limpieza y pintura interior y exterior de la torre de enfriamiento pasteur, de Air Liquide- Femsa',
    f4: 'Agosto de 2018 Yeisson Lozano – Camilo Zaraza'
  },
  {
    id: 't7',
    f1: 'BLASTINGMAR (ECOPETROL GRC) Cartagena',
    f2: 'UNIDAD DE SERVICIOS INDUSTRIALES',
    f3: 'Supervisor,  técnico operativo y coordinador (Empresa Mequinsa) durante los trabajos de lavado químico y pasivación de la tubería del economizador, evaporador y superheater, lado gases, caldera HRSG 002',
    f4: 'Julio y mayo de 2018 Javier Hernando Vernaza Ricardo Rodríguez Camargo',
  },
  {
    id: 't8',
    f1: 'Consorcio OBTC Colombia (ECOPETROL GRB) Barrancabermeja',
    f2: 'CALDERAS  HRSG 001 Y 002 UNIDAD DE SERVICIOS INDUSTRIALES CALDERA SB-954',
    f3: 'Supervisor,  técnico operativo y coordinador (Empresa Mequinsa) durante los trabajos de lavado químico y pasivación de la tubería del calentador de aire, lado gases, caldera SB-954',
    f4: 'Mayo de 2018 Bertha Ceballos -  Juan Carlos Toro'
  },
  {
    id: 't9',
    f1: 'Consorcio OBTC Colombia (ECOPETROL GRB) Barrancabermeja',
    f2: 'UNIDAD DE SERVICIOS INDUSTRIALES CALDERA SB-955 ',
    f3: 'Supervisor,  técnico operativo y coordinador (Empresa Mequinsa) durante los trabajos de lavado químico y pasivación de la tubería del calentador de aire, lado gases, caldera SB-955',
    f4: 'Abril de 2018 Bertha Ceballos -  Juan Carlos Toro '
  }
  // {
  //   id: 't10',
  //   f1: 'GECELCA TERMOGUAJIRA Dibulla -La Guajira',
  //   f2: 'UNIDAD 2',
  //   f3: 'Supervisor, técnico operativo y coordinador (empresa Maserpet), durante los trabajos de Lavado químico del condensador de superficie',
  //   f4: 'Noviembre  de  2017 Martín Angarita - Donaldo Pimienta - Juan Carlos Barros',
  // },
  // {
  //   id: 't11',
  //   f1: 'GECELCA TERMOGUAJIRA Dibulla-La guajira CONSORCIO OBTC (ECOPETROL GRB) Barrancabermeja',
  //   f2: 'UNIDAD 1 CENTRAL DEL NORTE SIREF',
  //   f3: 'Supervisor, técnico operativo y coordinador (empresa Maserpet), durante los trabajos de Lavado químico del condensador de superficie. Supervisor y técnico operativo (Empresa Maserpet) durante los trabajos de hervido caustico y pasivación de la caldera B-2405, central del norte',
  //   f4: 'Febrero  de  2017 Martín Angarita - Donaldo Pimienta - Juan Carlos Barros'
  // },
  // {
  //   id: 't12',
  //   f1: 'CONSORCIO OBTC (ECOPETROL GRB) Barrancabermeja',
  //   f2: 'UNIDAD DE BALANCE UBAL',
  //   f3: 'Supervisor y técnico operativo (Empresa Maserpet) durante los trabajos de hervido caustico y pasivación de la caldera B-2952, unidad de balance',
  //   f4: 'Noviembre  de 2017 Javier Vernaza - Orlando Romero - Juan Carlos Toro'

  // },
  // {
  //   id: 't13',
  //   f1: 'EMGESA Cartagena',
  //   f2: 'CALDERA 2',
  //   f3: 'Supervisor y técnico operativo (Empresa Maserpet) durante los trabajos de lavado químico y pasivación de paneles de tuberías en acero carbón,  en avanzado estado de corrosión.',
  //   f4: 'Noviembre  de 2017 Javier Vernaza - Orlando Romero - Juan Carlos Toro'
  // },
  // {
  //   id: 't14',
  //   f1: 'CONSORCIO MASA STORK (ECOPETROL GRC) Cartagena',
  //   f2: 'Alquilación U-044',
  //   f3: 'Supervisor , técnico operativo y coordinador (Empresa Maserpet) durante los trabajos de lavado químico lavado químico de intercambiadores de calor y líneas de recirculación del sistema de enfriamiento.',
  //   f4: 'Septiembre de 2016 Nay Pérez Juan Carlos Barros Junio de 2016 Raúl Arguelles - Jorge Vélez Juan Carlos Barros'
  // },
  // {
  //   id: 't15',
  //   f1: 'REFICAR (ECOPETROL GRC) Cartagena',
  //   f2: 'U-100 U-108 U-109 U-110 U-115 U-110',
  //   f3: 'Supervisor ,  técnico operativo y coordinador (Empresa Maserpet) durante los trabajos de lavado químico y pasivación de los intercambiadores, tambores y líneas asociadas a los sistema de enfriamiento .',
  //   f4: 'Febrero de 2016 Carlos Giraldo - Jorge Vélez Juan Carlos Barros'

  // },
  // {
  //   id: 't16',
  //   f1: 'CONSORCIO IDOM (ECOPETROL GRB) Barrancabermeja',
  //   f2: 'U-5100',
  //   f3: 'Supervisor, técnico operativo y coordinador  (Empresa Maserpet) durante los trabajos de hervido caustico y pasivación de las calderas B-5100 y B-5120 de la nueva planta de generación U-5100 ',
  //   f4: 'Octubre de 2015 Alberto Rojas – Rafael  Vega -  Juan Carlos Barros'
  // }

]

export default datot;