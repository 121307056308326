import React from 'react';
import './style.css';
import introImg2 from './acercade.png';

function IntroAcerca(){
    return(
        <div className="container intro-acerca">
            <div className="row">
                <div className="col-12 col-md-6">
                <h2>EN SU MISIÓN<br/>
                <span>MEQUINSA</span> <br/>
                ES UNA EMPRESA</h2>
                <p>
                    Dedicada al servicio de mantenimiento, limpieza química, limpieza mecánica y descontaminación de equipos industriales como: calderas, sistemas de enfriamiento, intercambiadores de calor, tanques y tuberías de proceso, entre otros.
                </p>
                <p>
                Nuestros servicios permiten la recuperación de eficiencia térmica, mediante procedimientos efectivos, con bajo impacto ambiental y con el mayor costo-beneficio. <br/>
                Nuestros procedimientos y equipos empleados generan bajos volúmenes de  efluentes y residuos, facilitando el tratamiento de estos.
                </p>
                <p>
                Mequinsa cuenta con personal técnico especialista y operativo de campo con más de 15 años de experiencia en las actividades. 
                </p>
    
                </div>
                <div className="col-12 col-md-6">
                    <img src={introImg2} className="img-fluid mx-auto d-block" alt=""/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <h2>
                    EN SU VISIÓN
                </h2>
                <p style={{marginTop:0}}>
                Mequinsa desea ser una empresa reconocida, con presencia nacional, especializada en limpieza y mantenimiento de equipos industriales, con productos de la más alta calidad, desarrollando procedimientos eficientes y respetuosos con el entorno,   que generen valor y permitan satisfacer las necesidades y espectativas de nuestros clientes, empleados y accionistas.
                </p>
                </div>
            </div>
        </div>
    )
}

export default IntroAcerca;