import React from 'react';
import './style.css';

export class Titulo extends React.Component {
    render() {
      return (
        <div className="titulo-acercade"> 
            <div className="subtitulo">NO<br/>SO<br/>TROS</div>
            <h1>{this.props.titulo}</h1>
        </div>
      )
    }
}