import React from 'react';
import './style.css';

export class Titulo extends React.Component {
    render() {
      return (
        <div className="titulo-contacto"> 
            <div className="subtitulo">CON<br/>NOSO<br/>TROS</div>
            <h1>{this.props.titulo}</h1>
        </div>

      )

    }
}