import React from 'react';
import './style.css';
import logoCliente from './logoClientes.jpg'

function Nclientes() {
  return (
    <div className="nclientes">
        <h2>NUESTROS</h2>
        <div className="contenedor">
            <h3>CLIENTES</h3>
            <img src={logoCliente} className="img-fluid d-block mx-auto" alt=""/>
        </div>

        
    </div>
    
  );
}

export default Nclientes;
