import React from 'react';
import './style.css';
import lcontacto from './logo-contacto.jpg'
import {Titulo} from './titulo';
import Himg from '../header/logo-header.png';
import JHeader from "../header";
function Contacto(){
    return(
        <div>
        <JHeader img={Himg} />
        <Titulo titulo='Contacta'/>
        <div className="container contacto">
            <div className="row">
                <div className="col-12">
                <h2>FORMULARIO DE COTIZACIÓN</h2>
                <img src={lcontacto} className="img-fluid d-block" alt=""/>
                <p>
                    Podemos cotizar tu solicitud, diligencia este formulario con tus datos
                </p>
                <div className="col-8 mx-auto">
                
                <form action="envia.php" method="POST">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <input type="text" name="nombre" className="form-control" placeholder="Nombre"/>
                        </div>
                        <div className="form-group col-md-6">
                        <input type="email" name='email' className="form-control" placeholder="E-mail"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <input type="text" className="form-control" name="cel" placeholder="Celular"/>
                        </div>
                        <div className="form-group col-md-6">
                        <input type="text" className="form-control" name="consulta" placeholder="Asunto"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="mensaje" rows="5" placeholder="Mensaje"></textarea>
                    </div>

                    <div className="form-group text-center">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>

                    </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Contacto;