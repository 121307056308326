import React from 'react';
import './style.css';

export class Titulo extends React.Component {
    render() {
      return (
        <div className="titulo-servicios"> 
            <div className="subtitulo">SER<br/>VI<br/>CIOS</div>
            <h1>{this.props.titulo}</h1>
        </div>
      )
    }
}