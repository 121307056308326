import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Inicio from './component/inicio';
import AcercaDe from './component/acercade';
import Servicios from './component/servicios';
import Contacto from './component/contacto';

import { HashRouter as Router, Route} from "react-router-dom";

import JFooter from './component/footer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


library.add(faEnvelope);
library.add(faCircle);
library.add(faFlag);
library.add(faInstagram);
library.add(faFacebookF);
library.add(faYoutube);
library.add(faWhatsapp);
library.add(faPhone);
library.add(faMapMarkerAlt);



function App() {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Inicio} />
        <Route path="/acercade" component={AcercaDe} />
        <Route path="/servicios" component={Servicios} />
        <Route path="/contacto" component={Contacto} />
        <JFooter/>
      </div>
    </Router>
  );
}

export default App;
