import React from 'react';
import Intro from './intro';
import Nservicio from './nservicios';
// import Suscripcion from './suscripcion';
import Banner2 from './banner2';
import './style.css';
import Himg from './logo-header-white.png';
import JHeader from "../header";

function Inicio() {
  return (
    <div>
        <JHeader img={Himg} />
        <Banner2/>
        <Intro/>
        <Nservicio/>
        {/* <Suscripcion/> */}
    </div>
    
  );
}

export default Inicio;
