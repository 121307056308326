import React from 'react';
import ReactSlickSlider from './react-slick-slider';
import './style.css';
function Nservicio() {
  return (
    <div className="nservicio">
        <h1>NUESTROS</h1>
        <div className="contenedor">
            <h2>SERVICIOS</h2>
        <div className="container">
        <ReactSlickSlider/>
        </div>
        
        </div>
    </div>
    
  );
}

export default Nservicio;
