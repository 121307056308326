import React, { Component } from "react";
import Slider from "react-slick";
import './style.css';
import S1 from './slider01.jpg';
import S2 from './slider02.jpg';
import S3 from './slider03.jpg';
import S4 from './slider04.jpg';
import S5 from './slider05.jpg';
import S6 from './slider06.jpg';
import S7 from './slider07.jpg';
import S8 from './slider08.jpg';

export default class Banner2 extends Component {

    render() {
        const settings = {
          infinite: true,
          arrows:false,
          pauseOnHover: false,
          autoplay:true,
          autoplaySpeed: 7000,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
        return (
          <div className="banner2">
            <Slider {...settings}>
              <img src={S1} className="img-fluid mx-auto" alt=""/>
              <img src={S2} className="img-fluid mx-auto" alt=""/>
              <img src={S3} className="img-fluid mx-auto" alt=""/>
              <img src={S4} className="img-fluid mx-auto" alt=""/>
              <img src={S5} className="img-fluid mx-auto" alt=""/>
              <img src={S6} className="img-fluid mx-auto" alt=""/>
              <img src={S7} className="img-fluid mx-auto" alt=""/>
              <img src={S8} className="img-fluid mx-auto" alt=""/>
            </Slider>
          </div>
        )
    }
}